import React, { useEffect, useRef } from 'react';

type InfiniteScrollProps = {
  children: React.ReactNode,
  dataLength: number,
  hasMore: boolean,
  next: () => void,
  loader?: React.ReactNode,
};

const gt = () => document.documentElement.scrollTop || document.body.scrollTop || window.scrollY;

export default function InfiniteScroll(props: InfiniteScrollProps) {
  const { children, hasMore, loader = null, next, dataLength } = props;
  const prevScroll = useRef(0);
  const Wrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const c = document.documentElement;
    const w = Wrapper.current;
    let actionTriggered = false;
    if (prevScroll.current && gt() > prevScroll.current) {
      c.scrollTop = prevScroll.current;
    }
    const offset = (w?.clientHeight ?? 0) + (w?.offsetTop ?? 0) - (1.1 * c.clientHeight + 400);
    const handleLoad = () => {
      if (actionTriggered) {
        prevScroll.current = gt();
        return;
      }
      if (hasMore && gt() >= offset) {
        prevScroll.current = gt();
        actionTriggered = true;
        next();
      }
    };
    handleLoad();
    window.addEventListener('scroll', handleLoad);
    window.addEventListener('resize', handleLoad);
    return () => {
      window.removeEventListener('scroll', handleLoad);
      window.removeEventListener('resize', handleLoad);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMore, dataLength, next]);
  return (
    <div>
      <div ref={Wrapper}>
        {children}
      </div>
      {hasMore && loader}
    </div>
  );
}
