// import { useRouterScroll } from '@moxy/next-router-scroll';
import { useCallback } from 'react';
import { useSWRInfinite } from 'swr';
import { Meta, clientSession, ResponseContainer, publicSession } from './apiService';

export interface UsePaginationProps<T = any> {
  initialUrl?: string;
  initialData?: { data: T[]; meta: Meta };
}

export default function usePagination<T = any>({ initialData, initialUrl }: UsePaginationProps<T>) {
  const {
    data: rawData,
    error,
    setSize,
    isValidating,
  } = useSWRInfinite(
    (pageIndex, prevData: { data: T[]; meta: Meta }) => {
      // console.log({ pageIndex, prevData });
      if (pageIndex === 0) {
        return (initialData ? initialData.meta?.next_url : initialUrl) ?? null;
      }
      return prevData?.meta?.next_url ?? null;
    },
    (url: string) => publicSession.get<0, ResponseContainer<T[], Meta>>(url).then(({ data, meta }) => ({ data, meta })),
    { initialData: initialData ? [] : undefined },
  );

  const dataSwr = initialData ? [initialData, ...(Array.isArray(rawData) ? rawData : [])] : rawData;

  const data = dataSwr?.reduce((a: T[], v) => [...a, ...(v.data || [])], []) ?? [];
  const loading = !dataSwr && !error;
  const remainingItems = (dataSwr ? dataSwr[dataSwr.length - 1].meta.remaining_items_count : initialData?.meta.remaining_items_count) ?? 0;
  const handleMore = useCallback(() => setSize((prev) => (rawData ? rawData.length + 1 : prev + 1)), [setSize, rawData]);

  const hasMore = !!dataSwr?.[dataSwr.length - 1]?.meta?.next_url;
  const getInfiniteScrollProps = () => ({
    hasMore,
    next: handleMore,
    dataLength: dataSwr?.length ?? 0,
    loader: <p>...</p>,
  });

  return {
    data,
    loading,
    error,
    remainingItems,
    isValidating,
    hasMore,
    handleMore,
    getInfiniteScrollProps,
  };
}
