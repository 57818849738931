import styled from '@emotion/styled';

const Banner = styled.div<{ href?: string, block?: boolean; }>(({ theme }) => ({
  position: 'relative',
  display: 'block',
  margin: theme.spacing(4, 0),
  background: 'no-repeat center url(/static/images/placeholder/banner.svg)',
  backgroundSize: 'cover',
  height: 0,
  paddingBottom: 540 / 2880 * 100 + '%',
  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(7, 0),
  },
}));

export default Banner;
