import { VideoArtist } from './apiService';
import Row from './styles/Row';
import ArtistAvatar from './ArtistAvatar';

export default function ArtistList({ artists, grouped }: { artists: VideoArtist[]; grouped?: boolean; }) {
  return (
    <Row cols={{ xs: 2, sm: 4, md: 6, lg: 8 }}>
      {(grouped ? groupArtists(artists) : artists).map((artist) => (
        <ArtistAvatar artist={artist} key={artist.person.id + '.' + artist.person_role.title} />
      ))}
    </Row>
  );
}

export const groupArtists = (artists: VideoArtist[]): VideoArtist[] => {
  const grouped: VideoArtist[] = [];
  [...artists].sort(sortByAvatar).sort(sortByRole).forEach((artist) => {
    const exists = grouped.findIndex(({ person: { id } }) => id === artist.person.id);
    if (grouped[exists]) {
      const currentRole = grouped[exists].person_role.title;
      const newRole = currentRole + '، ' + artist.person_role.title;
      grouped[exists] = { ...grouped[exists], person_role: { title: newRole } };
    } else {
      grouped.push(artist);
    }
  });
  return grouped;
};

export const sortByRole = (a: VideoArtist, b: VideoArtist) => {
  const { person_role: { title: role_a } } = a;
  const { person_role: { title: role_b } } = b;

  const roleOrder = [
    'بازیگر',
    'صدا پیشه',
    'راوی',
    'مجری',
    'فیلم خوره',
    'مهمان',
    'کارشناس',
    'نویسنده',
    'کارگردان',
    'تهیه کننده',
    'تدوینگر',
  ];

  if (roleOrder.includes(role_a) && roleOrder.includes(role_b)) {
    return roleOrder.indexOf(role_a) - roleOrder.indexOf(role_b);
  }
  return role_a < role_b ? -1 : 1;
};

export const sortByAvatar = (a: VideoArtist, b: VideoArtist) => {
  return (
    (a.person.avatar_image ? -1 : 1) - (b.person.avatar_image ? -1 : 1)
  );
};
