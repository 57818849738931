import React from 'react';
import { Content } from './apiService';
import CardBadge from './CardBadge';
import CountryYear from './CountryYear';
import { Box, Card, CardBody, CardImage, Ellipsis, Text } from './styles';
import { durationToWords, getGenreTitles } from './utils';
import Link from 'next/link';

interface VitrineCardProps {
  imagePath?: string;
  content: Content;
}

export default function VitrineCard({ imagePath, content }: VitrineCardProps) {
  const {
    short_id,
    title,
    slug,
    categories,
    duration,
    flag,
  } = content;
  const durationWords = duration && durationToWords(duration);

  const contentGenres = getGenreTitles(categories, 1);

  return (
    <Link href={`/contents/${short_id}/${slug}`}  >
      <a rel="nofollow">
        <Card
          orientation="horizontal"
          style={{ backgroundImage: 'url(/static/images/placeholder/promoted.svg)' }}
          title={title}
          block
        >
          <CardImage src={imagePath + '?w=300'} alt={title} loading="lazy" />
          <CardBadge flag={flag} />

          <CardBody px={2} py={3}>

            <Text mb={{ md: 1, lg: 2 }} variant="body2" as="h2" weight="demibold">
              <Ellipsis>{title}</Ellipsis>
            </Text>
            {durationWords && <Text variant="body3" as="div" mb={{ md: 0, lg: 1 }}>{durationWords}</Text>}
            <Text variant="body3" as="div">
              <Ellipsis>
                <CountryYear mb={1} {...content} textOnly limit={2} />
                {contentGenres && <><Box as="span" px={1}>-</Box>{contentGenres}</>}
              </Ellipsis>
            </Text>

          </CardBody>
        </Card>
      </a>
    </Link>
  );
}
