import { Card, BundleCardBody, Link, Text } from './styles';
import { Content, WidgetItemFranchise } from './apiService';
import { useResponsiveUrl } from './utils';

const getContentHref = (c: Content | null) => (c ? `/contents/${c.short_id}/${c.slug}` : '/');

const BundleCard = ({ video_content, english_name, persian_name, image }: WidgetItemFranchise) => {
  const imgUrl = useResponsiveUrl({ url: image?.path, desktop: 500, mobile: 250 });

  return (
    <Card
      orientation="square"
      style={{ backgroundImage: `url(${imgUrl})` }}
      as={Link}
      href={getContentHref(video_content)}
      title={persian_name}
      block
    >
      <BundleCardBody>
        <Text variant="body2">{english_name}</Text>
        <Text variant="h2">{persian_name}</Text>
      </BundleCardBody>
    </Card>
  );
};

export default BundleCard;
