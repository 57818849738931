import { Category } from './apiService';
import CategoryCard from './CategoryCard';
import Row from './styles/Row';

type Size = 'small' | 'normal' | 'large';

const cols = {
  normal: { xs: 2, sm: 3, md: 4, lg: 5, xl: 5 },
  large: { xs: 2, md: 3, lg: 4, xl: 5 },
};

const CategoryList = ({ items, size = 'normal' }: { items: Category[]; size?: Size }) => {
  return (
    <Row cols={cols[size]}>
      {items.map((item) => <CategoryCard key={item.id} {...item} />)}
    </Row>
  );
};

export default CategoryList;
