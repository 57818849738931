import styled from '@emotion/styled';
import CardBody from './CardBody';

const CategoryCardBody = styled(CardBody)(() => ({
  opacity: 1,
  backgroundSize: 'cover',
  backgroundColor: 'transparent',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
  transition: 'transform 2.5s',
  transform: 'scale(1, 1)',
  '&:hover, &:active, &:focus': {
    transform: 'scale(1.4, 1.4)',
  },
}));

export default CategoryCardBody;
