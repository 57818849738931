import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import router from 'next/router';
import { useEffect, useRef, useState } from 'react';
// import { ReactNode, useEffect, useRef, useState } from 'react';
import { Content, Widget, WidgetItemExclusive } from './apiService';
import CardBadge from './CardBadge';
// import HtmlParser from './HtmlParser';
import { IconAngleLeft, IconInfo, IconPlayClean } from './icons';
import { globalPadding } from './Layout/Layout';
import Scroller from './Scroller';
import { Box, CardBody, Icon, IconButton, Link, Spinner, Text } from './styles';
// import { Box, CardBody, Ellipsis, Icon, IconButton, Image, Link, Spinner, Text } from './styles';
import { ItemProps, ListProps, ResponsiveCols } from './styles/Row';
import theme from './theme';
// import TimerProgressBar from './TimerProgressBar';
import { deepMerge, usePlayerService } from './utils';
// import { deepMerge, getGenreTitles, sanitize, usePlayerService } from './utils';
import palette from './theme/paletteDark';
import { __t } from './i18n';
import Tooltip from './Tooltip';

const cols: ResponsiveCols = { xs: 2, sm: 3, md: 5 };
const slideDelay = 10;

const ExclusiveWidget = ({ widget }: { widget: Widget<'exclusive'> }) => {
  const {
    configuration: { items, navigation_configuration },
    display_title,
  } = widget;

  const [activeItem, setActiveItem] = useState<number>();
  const [paused, setPaused] = useState(false);
  const [disableEffect, setDisableEffect] = useState(true);

  const open = activeItem !== undefined;

  const { breakpoints } = useTheme();
  useEffect(() => {
    const getDisabled = () => {
      const _disable = window.innerWidth < breakpoints.md;
      setDisableEffect(_disable);
      setPaused(_disable);
      setActiveItem((idx) => (_disable ? undefined : idx || 0));
    };

    getDisabled();
    window.addEventListener('resize', getDisabled);

    return () => window.removeEventListener('resize', getDisabled);
  }, [breakpoints.md]);

  const handleChange = (idx?: number) => {
    setActiveItem(disableEffect ? undefined : idx);
  };

  const handleClick = (idx: number) => {
    if (!disableEffect) {
      setPaused(true);
      return handleChange(idx);
    }

    const content = items[idx].video_content;
    const { short_id, slug } = content || {};
    const url = `/contents/${short_id}/${slug}`;
    router.push(url);
  };

  const handleHover = (idx: number, paused: boolean) => {
    if (idx !== activeItem) return;
    setPaused(paused);
  };

  const listRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const el = listRef.current;
    if (!el) return;

    const setHeight = () => {
      if (window.innerWidth < breakpoints.md) {
        el.style.height = '';
      } else {
        const ratio = 0.36;
        const width = el.clientWidth - 16;
        const height = width * ratio;
        el.style.height = `${height}px`;
      }
    };

    setHeight();
    window.addEventListener('resize', setHeight);
    return () => window.removeEventListener('resize', setHeight);
  }, [breakpoints.md]);

  const _items = items.slice(0, 5);

  useEffect(() => {
    if (!paused) {
      const timerId = setTimeout(() => {
        let newIndex = 0;
        setActiveItem(newIndex);
        if (activeItem !== undefined) newIndex = activeItem + 1;
        if (newIndex === _items.length) newIndex = 0;

        setActiveItem(newIndex);
      }, slideDelay * 1000);

      return () => clearTimeout(timerId);
    }
  }, [_items.length, activeItem, paused]);

  return (
    <Box>
      <Text variant="h2" weight="medium" mb={2}>
        <TitleElements title={display_title} url={navigation_configuration?.api_url} />
      </Text>
      <Scroller arrows={false} cancelPadding={globalPadding}>
        <List cols={cols} nowrap ref={listRef}>
          {_items.map((item, idx) => (
            <ExWidgetItem
              key={idx}
              idx={idx}
              open={open}
              active={activeItem}
              onChange={handleChange}
              onClick={handleClick}
              paused={paused}
              onMouseEnter={() => handleHover(idx, true)}
              onMouseLeave={() => handleHover(idx, false)}
              {...item}
            />
          ))}
        </List>
      </Scroller>
    </Box>
  );
};

// -------------- //
// SUB-COMPONENTS //
// -------------- //

const MoreLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [`${Icon}`]: {
    margin: '0 -.1em',
    fontSize: '1.2em',
    transition: 'color .3s ease',
  },
  [`&:hover ${Icon}`]: {
    color: theme.palette.primary[800][0],
  },
}));

const TitleElements = ({ title, url }: { title: string, url?: string }) => {
  return (
    <MoreLink href={url || '#'}>
      {title}
      <Icon cursor="pointer"><IconAngleLeft /></Icon>
    </MoreLink>
  );
};

const ExWidgetItem = ({
  cover_image,
  poster_image,
  // logo_image,
  video_content,
  // summery,
  idx,
  active,
  open,
  // paused,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: ExWidgetItemProps) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);

  const isOpen = idx === active;
  const isClosed = open && idx !== active;
  if (!video_content) return <></>;

  const { short_id, slug, flag } = (video_content as Content | undefined) || {};
  const href = `/contents/${short_id}/${slug}`;
  const hoverHandlers = { onMouseEnter, onMouseLeave };

  return (
    <Item cols={cols} open={isOpen} closed={isClosed}>
      <ExCard onClick={() => onClick(idx)} {...hoverHandlers}>
        {flag && <CardBadge flag={flag}/>}
        <ExBody cover={cover_image?.path} poster={poster_image?.path}>
          {/* <ExLogo src={logo_image?.path} style={{ opacity: !isOpen ? 1 : 0 }} /> */}
          {/* TO DO : pick color from theme */}
          <InfoLink ref={setReferenceElement} href={href} hide={!isOpen}>
            <Icon size="large" style={{ color: palette.background[100][0] }} cursor="pointer">
              <IconInfo/>
            </Icon>
            <Tooltip
              anchor={referenceElement}
              placement="top-start"
              type="text"
            >
              {__t('common.more_info')}
            </Tooltip>
          </InfoLink>

          <PlayButtonWrapper hide={!isOpen}>
            <PlayButton id={short_id} href={href} flag={flag}/>
          </PlayButtonWrapper>

          {/* <InnerContent isOpen={isOpen} {...video_content} summary={summery || video_content?.summary}> */}
          {/*   <TimerProgressBar duration={!paused && isOpen ? slideDelay : 0} mt={2} style=
          {{ opacity: isOpen && !paused ? 0.5 : 0 }} /> */}
          {/* </InnerContent> */}
        </ExBody>
      </ExCard>
    </Item>
  );
};

// const InnerContent = ({ isOpen, title, categories, summary, children }: Content & { isOpen: boolean; children?: ReactNode }) => {
//   const contentGenres = getGenreTitles(categories);
//   const { truncated: text } = sanitize({ raw: summary || '', limit: 200 });
//
//   return (
//     <InnerContentWrapper style={{ opacity: isOpen ? 1 : 0 }} px={3}>
//       <Text variant="h2" mb={2}>
//         <Ellipsis>{title}</Ellipsis>
//       </Text>
//       <Text variant="h3" mb={1}>
//         {contentGenres}
//       </Text>
//       <Text variant="body1" as="div">
//         <HtmlParser data={text}/>
//         {children}
//       </Text>
//     </InnerContentWrapper>
//   );
// };

const PlayButton = ({ id = '', flag = '' }: { id?: string; href: string; flag?: string }) => {
  const [handlePlay, { loading }] = usePlayerService({ id });
  const type = isPlayable(flag) ? 'p' : 't';

  return (
    <IconButton variant="primaryA" round p={2} onClick={() => handlePlay(type)} isPlay>
      {loading ? (
        <Spinner size="large"/>
      ) : (
        <Icon size="large" cursor="pointer">
          <IconPlayClean/>
        </Icon>
      )}
    </IconButton>
  );
};

const isPlayable = (flag: string) => !/(coming_soon|in_production)/.test(flag);

// ------ //
// STYLES //
// ------ //

const transitionDuration = 500;
const getTransition = (props: string[]) => props.map((s) => `${s} ${transitionDuration}ms ease`).join(', ');

const List = styled.ul<ListProps>(({ theme, gap = 2, justify = 'flex-start', nowrap, direction = 'row' }) => {
  return {
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: direction,
    flexWrap: nowrap ? 'nowrap' : 'wrap',
    ...theme.mixins.create(gap, (n) => ({
      [theme.logicalProp('margin', 'start')]: `-${theme.spacing(n)}`,
    })),
    justifyContent: justify,
  };
});

const Item = styled.li<ItemProps & ExItemProps>(({ theme, gap = 2, cols, open, closed }) => {
  const responsiveCols = theme.mixins.create(cols, (n) => {
    if (n === 'auto')
      return {
        minWidth: 'auto',
        maxWidth: 'none',
      };
    else if (n) {
      let _n = n;
      if (open) _n -= 3.277;
      if (closed) _n += 4.53;
      const w = 100 / _n;
      return {
        minWidth: `${w}%`, // `${w - (w / n / 3)}%`,
        maxWidth: `${w}%`, // `${w - (w / n / 3)}%`,
      };
    }
    return {};
  });

  return {
    listStyleType: 'none',
    listStyleImage: 'none',
    display: 'inline-block', // IE 9 fallback
    ...deepMerge(
      {},
      theme.mixins.create(gap, (n) => ({
        [theme.logicalProp('padding', 'start')]: theme.spacing(n),
      })),
      responsiveCols,
    ),
    transition: getTransition(['min-width', 'max-width']),
  };
});

// const logoWidth = {
//   xs: '5rem',
//   md: '4rem',
//   lg: '6rem',
// };

// const ExLogo = styled(Image)(({ theme }) => ({
//   transition: getTransition(['opacity']),
//   position: 'absolute',
//   bottom: theme.spacing(5),
//   left: '50%',
//   transform: 'translateX(-50%)',
//   ...theme.mixins.create(logoWidth, (w) => ({ width: w })),
// }));

const InfoLink = styled(Link)<{ hide?: boolean }>(({ hide }) => ({
  position: 'absolute',
  fontSize: '1.3rem',
  bottom: theme.spacing(3),
  left: theme.spacing(3),

  opacity: hide ? 0 : 1,
  transition: getTransition(['opacity']),
  pointerEvents: hide ? 'none' : 'all',
}));

const PlayButtonWrapper = styled('div')<{ hide: boolean }>(({ hide }) => ({
  transition: getTransition(['opacity', 'transform']),
  opacity: hide ? 0 : 1,
  position: 'absolute',
  zIndex: 1,
  top: '50%',
  left: '50%',
  transform: `translate(-50%, -50%) scale(${hide ? 0 : 1})`,
  padding: theme.spacing(5),
  fontSize: '1.5rem',
  [`${IconButton}`]: {
    display: 'block',
  },
}));

const ExCard = styled(Box)(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.radius.medium,
  overflow: 'hidden',
  display: 'block',

  background: 'no-repeat center url(/static/images/placeholder/poster.svg)',
  backgroundSize: 'cover',
  width: '100%',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    cursor: 'pointer',
    paddingTop: '195%',
  },
}));

const ExBody = styled(CardBody)<CardProps>(({ theme, poster, cover }) => ({
  backgroundColor: 'transparent',
  opacity: 1,
  position: 'absolute',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  background: `no-repeat center url(${poster}?w=276)`,
  backgroundSize: 'cover',
  [theme.breakpoints.up('md')]: {
    backgroundImage: `url(${cover}?w=800)`,
  },
}));

// const contentWidth = { md: 22, lg: 31 };

// const InnerContentWrapper = styled(Box)(({ theme }) => ({
//   [theme.breakpoints.up('md')]: {
//     width: `${contentWidth.md}rem`,
//     position: 'relative',
//     pointerEvents: 'none',
//     transition: getTransition(['opacity']),
//     textShadow: `0 0 .25rem ${theme.palette.background[800][0]}`,
//     [`${TimerProgressBar}`]: {
//       transition: 'opacity .3s ease',
//       width: '95%',
//     },
//   },
//   [theme.breakpoints.up('lg')]: {
//     width: `${contentWidth.lg}rem`,
//   },
// }));

// ----- //
// TYPES //
// ----- //

interface ExItemProps {
  open: boolean;
  closed: boolean;
}

interface CardProps {
  cover?: string;
  poster?: string;
}

type ExWidgetItemProps = WidgetItemExclusive & {
  idx: number;
  active?: number;
  open: boolean;
  paused: boolean;
  onChange: (idx?: number) => void;
  onClick: (idx: number) => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

export default ExclusiveWidget;
