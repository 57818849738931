/* eslint-disable no-restricted-syntax */
import { NavigationConfiguration } from 'common/apiService';

const getBaseUrlByNavigationType = (navigation_configuration?: NavigationConfiguration, short_id?: string) => {
  switch (navigation_configuration?.navigation_type) {
    case 'get_by_filter':
      return 'contents';

    case 'content':
      return `/contents/${short_id ? short_id : navigation_configuration.content_id}`;

    case 'category':
      return `/categories/${navigation_configuration.category_id}`;

    case 'person':
      return `/artists/${navigation_configuration.person_id}`;

    case 'persons':
      return '/artists';

    case 'tag':
      return `/tags/${navigation_configuration.tag_id}`;

    default:
      return navigation_configuration?.navigation_type ?? '';
  }
};

const getURL = (navigation_configuration?: NavigationConfiguration, slug?: string, short_id?: string) => {
  const { api_url = '', url, ...rest } = navigation_configuration ?? {};
  const baseUrl = getBaseUrlByNavigationType(navigation_configuration, short_id);
  let params = rest;
  const slugUrl = slug ? '/' + slug : '';

  if (url) {
    return url.replace('video-contents', 'contents') + slugUrl;
  }

  if (api_url) {
    const splittedUrl = api_url.split('?');
    // baseUrl = (splittedUrl[0] || '').replace('video-contents', 'contents');
    params = splittedUrl.length > 1 ? JSON.parse(`{"${decodeURI(splittedUrl[1].replace(/&/g, '","').replace(/=/g, '":"'))}"}`) : '';
  }

  if (Object.values(params).filter((val) => !!val).length > 0) {
    let str = '';
    for (const key in params) {
      if (params[key]) {
        if (str !== '') {
          str += '&';
        }
        str += `${key}=${encodeURIComponent(params[key])}`;
      }
    }

    return `${baseUrl}?${str}`;
  }

  return baseUrl + slugUrl;
};

export default function getNavigationUrl(navigation_configuration?: NavigationConfiguration, slug?: string, short_id?: string) {
  if (navigation_configuration?.navigation_type === 'none') {
    return '#';
  }
  return getURL(navigation_configuration, slug, short_id)
    .replace(/category_id/g, 'categories')
    .replace(/tag_id/g, 'tags')
    .replace(/content_type/g, 'types')
    .replace(/sort_order/g, 'order') || '#';
}
