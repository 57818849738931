import styled from '@emotion/styled';
import withStyleSystem from 'common/withStyleSystem';
import { SpacingProps } from 'common/withStyleSystem/withSpacingStyles';
import { Card } from '.';
import CardImage from './CardImage';
import Link from './Link';

type AvatarCardWrapperProps = {
  size?: string;
  href?: string;
  placeholder?: string;
};

export const AvatarCardWrapper = withStyleSystem(styled(Card)<AvatarCardWrapperProps>(({
  theme,
  placeholder = '/static/images/avatar.svg',
}) => ({
  borderRadius: theme.radius.pill,
  width: '100%',
  minWidth: '6rem',
  overflow: 'hidden',
  backgroundImage: `url(${placeholder})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
})));

type AvatarCardProps = {
  image?: string,
  placeholder?: string,
  href: string,
  alt?: string,
} & SpacingProps;

export default function AvatarCard({ image, href, alt, ...rest }: AvatarCardProps) {
  return (
    <AvatarCardWrapper orientation="square" title={alt} as={Link} href={href} {...rest}>
      {image && <CardImage src={image + '?w=200'} alt={alt} />}
    </AvatarCardWrapper>
  );
}
