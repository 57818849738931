import styled from '@emotion/styled';
import { LiveBadge } from 'pages/live-stream';
import { useEffect, useState } from 'react';
import { Content } from './apiService';
import LivePlayButton from './LivePlayButton';
import { Card, CardBody, Text } from './styles';
import { useResponsiveUrl } from './utils';

export default function LiveWidgetCard(props: LiveWidgetCardProps) {
  const { poster_image, short_id, title } = props.content;
  const imgUrl = useResponsiveUrl({ url: poster_image?.path, desktop: 500, mobile: 250 });
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);

  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    if (referenceElement) {
      const handleMouseEnter = () => setIsHovering(true);
      const handleMouseLeave = () => setIsHovering(false);

      referenceElement.addEventListener('mouseenter', handleMouseEnter);
      referenceElement.addEventListener('focus', handleMouseEnter);
      referenceElement.addEventListener('mouseleave', handleMouseLeave);
      referenceElement.addEventListener('blur', handleMouseLeave);
      return () => {
        referenceElement.removeEventListener('mouseenter', handleMouseEnter);
        referenceElement.removeEventListener('focus', handleMouseEnter);
        referenceElement.removeEventListener('mouseleave', handleMouseLeave);
        referenceElement.removeEventListener('blur', handleMouseLeave);
      };
    }
  }, [referenceElement]);

  return (
    <>
      <Card
        orientation="horizontal"
        title={title}
        block
        ref={setReferenceElement}
      >
        <LiveWidgetCardBody style={{ backgroundImage: imgUrl && `url(${imgUrl})` }} />
        {props.playable &&  <LivePlayButton id={short_id} size="medium" hide={!isHovering}/>}
        <LiveBadge>{props.playable ? 'در حال پخش' : props.live_description}</LiveBadge>
      </Card>
      <Text variant="body3" mt='0.5rem'>{title}</Text>
    </>
  );
}

const LiveWidgetCardBody = styled(CardBody)(() => ({
  opacity: 1,
  backgroundSize: 'cover',
  backgroundColor: 'transparent',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
  transition: 'transform 2.5s',
  transform: 'scale(1, 1)',
}));

interface LiveWidgetCardProps {
  content: Content,
  playable: boolean,
  live_description: string | undefined
}
