import styled from '@emotion/styled';
import React from 'react';
import { Content } from './apiService';
import ContentGenres from './ContentGenres';
import ContentRatings from './ContentRatings';
import CountryYear from './CountryYear';
import { Card, CardBody, CardImage, Link, Text } from './styles';

interface PromotedCardProps {
  imagePath?: string;
  content: Content;
}

const Container = styled.div(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '& .desktop-only': {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 2),
    '& .desktop-only': {
      display: 'block',
    },
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(2, 4),
  },
}));

const Title = styled(Text)(({ theme }) => ({
  fontWeight: theme.font.weight.demibold,
  ...theme.font.responsiveSize(1),
  [theme.breakpoints.up('md')]: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    flexShrink: 0,
  },
}));

export default function PromotedCard({ imagePath, content }: PromotedCardProps) {
  const { short_id, page_title: title, slug, categories } = content;

  return (
    <Card
      orientation="horizontal"
      style={{ backgroundImage: 'url(/static/images/placeholder/promoted.svg)' }}
      as={Link}
      href={`/contents/${short_id}/${slug}`}
      title={title}
      block
    >
      <CardImage src={`${imagePath}?w=500`} alt={title} loading="lazy" />
      <CardBody>
        <Container>
          <Title mb={1} variant="body1" as="h2">{title}</Title>
          <div className="desktop-only">
            <CountryYear mb={1} label {...content} />
            <ContentRatings styleSystem={{ mb: 1 }} {...content} />
          </div>
          <ContentGenres categories={categories} link={false} nowrap />
        </Container>
      </CardBody>
    </Card>
  );
}
