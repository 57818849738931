import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { getNavigationUrl, useResponsiveUrl } from './utils';
import { Banner, CardImage, Link, Row } from 'common/styles';
import {
  CompactConditionalProps, continueWatchingData,
  getCompactConditionalProp, removeUserContinueWatching,
  Widget,
  WidgetLiveItemCarousel,
  WidgetType,
} from './apiService';
import Carousel from './Carousel';
import ContentList from './ContentList';
import CategoryList from './CategoryList';
import PromotedCardList from './PromotedCardList';
import ArtistList from './ArtistList';
import BundleCard from './BundleCard';
import Vitrine from './Vitrine';
import { globalPadding } from './Layout/Layout';
import ExclusiveWidget from './ExclusiveWidget';
import LiveWidgetList from './LiveWidgetList';
import { widgetsConditionalFlagChecker } from './WidgetsConditionCheck';
import ContinueWatchingList from './widgets/continue_watching/ContinueWatchingList';

export const WidgetWrapper = styled.div<{ cancelPadding?: boolean }>(({ theme, cancelPadding }) => ({
  ...(cancelPadding ? theme.mixins.create(globalPadding, (p) => ({ margin: `0 -${theme.spacing(p)}` })) : undefined),
  marginTop: '4rem',
  position: 'relative',
  '&:first-of-type': { marginTop: 0 },
}));

const FranchiseWidget = ({ widget }: { widget: Widget<'franchise'> }) => {
  const {
    id,
    configuration: { items },
    display_title,
  } = widget;
  return (
    <Carousel m={0} title={display_title}>
      <Row id={id} cols={{ xs: 2, sm: 3, md: 4, lg: 6 }}>
        {items.map(({ video_content, english_name, persian_name, image }, i) => (
          <BundleCard
            key={video_content?.short_id || i}
            video_content={video_content}
            english_name={english_name}
            persian_name={persian_name}
            image={image}
          />
        ))}
      </Row>
    </Carousel>
  );
};

const CarouselWidget = ({ widget }: { widget: Widget<'regular_carousel' | 'promoted_carousel'> }) => {
  const {
    configuration: { items, navigation_configuration },
    display_title,
  } = widget;
  const more = navigation_configuration?.api_url;

  return (
    <Carousel m={0} title={display_title?.trim()} more={more}>
      <ContentList items={items.map(({ video_content }) => video_content)} />
    </Carousel>
  );
};

const ContinueWatchingWidget = ({ widget }: { widget: Widget<'continue_watching'> }) => {
  const {
    configuration: { items, navigation_configuration },
    display_title,
  } = widget;
  const more = navigation_configuration?.api_url || navigation_configuration?.url;
  const [continueData, setContinueData] = useState<continueWatchingData[]>([]);
  const [showArrow, setShowArrow] = useState<boolean>(true);
  useEffect(() => {
    const data: continueWatchingData[] = [];
    // eslint-disable-next-line array-callback-return
    items.map(({ video_content }) => {
      data.push(video_content);
    });
    setContinueData(data);
  }, [items]);
  const handleDeleteContent = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
    e.stopPropagation();
    await removeUserContinueWatching({ id });
    const listData: continueWatchingData[] = [...continueData];
    const index = listData.findIndex((item) => item.id === id);
    listData.splice(index, 1);
    setContinueData(listData);
  };
  useEffect(() => {
    const offsetWidth = Number(window.innerWidth);
    if (offsetWidth > 1280 && continueData.length <= 8) {
      setShowArrow(false);
    } else if (960 > offsetWidth && offsetWidth <= 1280 && continueData.length <= 6) {
      setShowArrow(false);
    } else if (offsetWidth <= 960) {
      setShowArrow(false);
    } else {
      setShowArrow(true);
    }
  }, [continueData]);

  return <>
    {continueData && continueData.length > 0 && <Carousel m={0} title={display_title?.trim()} more={more} arrows={showArrow}>
      {<ContinueWatchingList items={continueData} deleteContent={handleDeleteContent} />}
    </Carousel>}
  </>;
};

const PosterWidget = ({ widget }: { widget: Widget<'poster'> }) => {
  const {
    configuration: { items },
    display_title,
  } = widget;
  const [{ image_path, navigation_configuration }] = items;
  const imgUrl = useResponsiveUrl({ url: image_path, desktop: 1920, mobile: 880 });

  return (
    <Banner as={Link} href={getNavigationUrl(navigation_configuration, display_title)} title={display_title} block>
      <CardImage src={imgUrl} alt={display_title} />
    </Banner>
  );
};

const PromotedWidget = ({ widget }: { widget: Widget<'promoted'> }) => {
  const {
    configuration: { items },
  } = widget;

  return <PromotedCardList items={items} title={widget.display_title} />;
};

const CategoryWidget = ({ widget }: { widget: Widget<'category_carousel'> }) => {
  const {
    display_title,
    configuration: { items },
  } = widget;
  return (
    <Carousel m={0} title={display_title}>
      <CategoryList items={items.map(({ category }) => category)} />
    </Carousel>
  );
};

const LiveWidget = ({ widget }: { widget: Widget<'live_stream'> }) => {
  const [playableList, setPlayableList] = useState<CompactConditionalProps[]>([]);

  const fetchCompactConditionalFlags = async (body) => {
    try {
      const res = await getCompactConditionalProp(body);
      setPlayableList(res.data);
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    const itemIds = items.map((i) => i.content_id);
    fetchCompactConditionalFlags(itemIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    display_title,
    configuration: { items },
  } = widget;

  const list: WidgetLiveItemCarousel[] = useMemo(() => widgetsConditionalFlagChecker<WidgetLiveItemCarousel, CompactConditionalProps>(items, 'content_id', playableList, 'id', 'conditional_flag', 'playable', 'playable'), [items, playableList]);

  return (
    <Carousel m={0} title={display_title} customUrl="/live-stream" more="live">
      <LiveWidgetList
        items={list}
      />
    </Carousel>
  );
};

const ArtistWidget = ({ widget }: { widget: Widget<'artist_carousel'> }) => {
  const {
    display_title,
    configuration: { items },
  } = widget;
  return (
    <Carousel m={0} title={display_title}>
      <ArtistList artists={items.map(({ person }) => ({ person, person_role: { title: person.name_en || '' } }))} />
    </Carousel>
  );
};

const getWidgetType = (type: WidgetType): FC<{ widget: Widget }> | null => {
  switch (type) {
    case 'franchise': return FranchiseWidget;
    case 'regular_carousel':
    case 'promoted_carousel': return CarouselWidget;
    case 'poster': return PosterWidget;
    case 'promoted': return PromotedWidget;
    case 'category_carousel': return CategoryWidget;
    case 'artist_carousel': return ArtistWidget;
    case 'vitrine': return Vitrine;
    case 'exclusive': return ExclusiveWidget;
    case 'live_stream': return LiveWidget;
    case 'continue_watching': return ContinueWatchingWidget;
    default: return null;
  }
};

const WidgetItem = ({ widget }: { widget: Widget }) => {
  const Component = widget.content_category_type ? getWidgetType(widget.content_category_type) : getWidgetType(widget.type);
  if (!Component) return null;

  const cancelPadding = widget.type === 'poster';
  return (
    <WidgetWrapper cancelPadding={cancelPadding}>
      <Component widget={widget} />
    </WidgetWrapper>
  );
};

export default function WidgetManager({ widgets }: { widgets: Widget[] }) {
  return (
    <>
      {widgets && widgets.map((w) => (
        <WidgetItem key={w.id} widget={w} />
      ))}
    </>
  );
}
