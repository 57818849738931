import { Fragment, useEffect, useState } from 'react';
import { Placeholder, Row, Card, Text } from './styles';
import { ResponsiveCols } from './styles/Row';

export default function ContentListLoading({
  length = 6,
  cols = { xs: 2, sm: 4, md: 6, lg: 8 },
  debounce,
}: {
  length?: number;
  debounce?: number;
  cols?: ResponsiveCols;
}) {
  const items: number[] = [];
  while (items.length < length) items.push(items.length + 1);

  const [show, setShow] = useState(!debounce);

  useEffect(() => {
    if (!debounce) return;

    const timer = setTimeout(() => setShow(true), debounce);
    return () => clearTimeout(timer);
  }, [debounce]);

  return (
    <Row cols={cols} mt={2} style={{ opacity: show ? 1 : 0, transition: 'opacity .5s ease' }}>
      {items.map((item) => (
        <Fragment key={item}>
          <Placeholder as={Card} key={item} fullWidth block />
          <Text variant="body2" pt={1}>
            <Placeholder />
          </Text>
        </Fragment>
      ))}
    </Row>
  );
}
