import { Content } from './apiService/types';
import ContentCard from './ContentCard';
import Row from './styles/Row';
import { SpacingProps } from './withStyleSystem/withSpacingStyles';

const ContentList = ({ items, id, ...spacingProps }: { items: Content[], id?: string } & SpacingProps) => {
  return (
    <Row id={id} cols={{ xs: 2, sm: 4, md: 6, lg: 8 }} {...spacingProps}>
      {items.filter((item) => item?.short_id).map((item) => <ContentCard key={item.short_id} content={item} />)}
    </Row>
  );
};

export default ContentList;
