import styled from '@emotion/styled';

const BundleCardBody = styled.div(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '90%',
  top: 0,
  left: 0,
  opacity: 1,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(1),
}));

export default BundleCardBody;
