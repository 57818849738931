import { WidgetLiveItemCarousel } from './apiService/types';
import LiveWidgetCard from './LiveWidgetCard';
import Row from './styles/Row';
import { SpacingProps } from './withStyleSystem/withSpacingStyles';

const cols = {
  normal: { xs: 2, sm: 3, md: 4, lg: 4, xl: 4 },
  large: { xs: 2, md: 3, lg: 4, xl: 5 },
};

type Size = 'small' | 'normal' | 'large';

const LiveWidgetList = ({ items, id, size = 'normal', ...spacingProps }: { items: WidgetLiveItemCarousel[], id?: string, size?: Size } & SpacingProps) => {
  return (
    <Row id={id} cols={cols[size]} {...spacingProps}>
      {items.map((item) => {
        return <LiveWidgetCard key={item.content_id} content={item.video_content}
          playable={item.playable ||  false} live_description={item.live_description} />;
      })}
    </Row>
  );
};

export default LiveWidgetList;
