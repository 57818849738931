import { Category } from './apiService';
import { Card, CategoryCardBody, Link, Text } from './styles';
import { useResponsiveUrl } from './utils';

export default function CategoryCard({ short_id, id, title, slug, type, thumbnail_image_file: { path: image } = {},
  special_type }: Category) {
  const exception_category_expressions = ['none', 'all'];
  const exception_category = special_type && !exception_category_expressions.includes(special_type) ? special_type.replace('_', '-') : undefined;
  const renderHref = () => {
    if (type === 'genre') {
      return `/contents?order=latest&categories=${id}`;
    } else if (exception_category) {
      return `/${exception_category}`;
    } return `/categories/${short_id}/${slug}`;
  };

  const imgUrl = useResponsiveUrl({ url: image, desktop: 500, mobile: 250 });
  return (
    <>
      <Card
        orientation="horizontal"
        as={Link}
        href={renderHref()}
        title={title}
        block
      >
        <CategoryCardBody style={{ backgroundImage: image && `url(${imgUrl})` }} />
      </Card>
      <Text variant="body1" as="h3" mt='0.5rem' mb='1rem'>{title}</Text></>
  );
}
