import styled from '@emotion/styled';
import React from 'react';
import { WidgetItemPromoted } from './apiService';
import PromotedCard from './PromotedCard';
import { Text } from './styles';

const Container = styled.div(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: 'auto',
  gap: theme.spacing(2),
  gridTemplateAreas: '"item0 item0" "item1 item2"',
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    marginLeft: `-${theme.spacing(2)}`,
    gap: 0,
  },
}));

const CardWrapper = styled.div<{ index: number }>(({ theme, index }) => ({
  gridArea: `item${index}`,
  [theme.breakpoints.up('sm')]: {
    width: `${100 / 3}%`,
    marginLeft: theme.spacing(2),
  },
}));

export default function PromotedCardList({ items, title }: { items: WidgetItemPromoted[], title: string }) {
  return (<>
    {title && (
      <Text variant="h2" weight="medium" mb={1}>
        {title}
      </Text>
    )}
    <Container>
      {items.map(({ image: { path } = {}, video_content: content }, index) => {
        if (!content) return <></>;
        return <CardWrapper index={index} key={content?.short_id}>
          <PromotedCard imagePath={path} content={content} />
        </CardWrapper>;
      })}
    </Container>
  </>
  );
}
