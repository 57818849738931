import React from 'react';
import { CategoryList } from './apiService';
import { Link, Row, Tag } from './styles';
import { TagProps } from './styles/Tag';
import { getGenres, getGenreTitles } from './utils';
import { SpacingProps } from './withStyleSystem/withSpacingStyles';

type ContentGenresProps = {
  categories?: CategoryList[];
  link?: boolean;
  color?: TagProps['color'];
  nowrap?: boolean;
  textOnly?: boolean;
  limit?: number;
} & SpacingProps;

export default function ContentGenres({ categories, link = true, color, nowrap, textOnly, limit, ...styleSystem }: ContentGenresProps) {
  if (textOnly) return <>{getGenreTitles(categories, limit)}</>;

  const renderHref = (special_type, short_id, slug) => {
    if (special_type) {
      const exception_category_expressions = ['none', 'all'];
      const exception_category = special_type && !exception_category_expressions.includes(special_type) ? special_type.replace('_', '-') : undefined;
      return `/${exception_category}`;
    }
    return `/categories/${short_id}/${slug}`;
  };

  return (
    <Row {...styleSystem} nowrap={nowrap}>
      {getGenres(categories, limit)?.map(({ title, special_type, short_id, slug }) => (
        <Tag
          key={title}
          as={link ? Link : 'div'}
          href={link ? renderHref(special_type, short_id, slug) : undefined}
          color={color}
        >
          {title}
        </Tag>
      ))}
    </Row>
  );
}
