import React from 'react';
import AvatarCard from './styles/AvatarCard';
import { Text, Flex, Box } from './styles';
import { VideoArtist } from './apiService';
import Ellipsis from './styles/Ellipsis';

export default function ArtistAvatar({ artist }: { artist: VideoArtist }) {
  const { person: { short_id, name, name_en, slug, avatar_image }, person_role } = artist;
  return (
    <Flex align="center" direction="column">
      <AvatarCard
        image={avatar_image?.path}
        placeholder="/static/images/placeholder/star.svg"
        href={`/artists/${short_id}/${slug}`}
        alt={`${name} | ${name_en}`}
        data-center-ref
      />
      <Box pt={1}>
        <Text variant="body1" as="span" align="center"><Ellipsis>{name}</Ellipsis></Text>
        <Text variant="body2" as="span" align="center"><Ellipsis style={{ opacity: .5 }}>{person_role.title}</Ellipsis></Text>
      </Box>
    </Flex>
  );
}
