import { Content, Widget } from './apiService';
import { globalPadding } from './Layout/Layout';
import Scroller from './Scroller';
import { Box, Row, Text } from './styles';
import VitrineCard from './VitrineCard';
import { SpacingProps } from './withStyleSystem';

const Vitrine = ({ widget }: { widget: Widget<'vitrine'>; }) => {
  const { configuration: { items }, display_title } = widget;

  const _items = [...items]
    .map(({
      image: { path: image } = {},
      video_content: content,
    }) => ({ content, image }))
    .filter(({ content }) => content?.id);

  const row1 = _items.slice(0, 5);
  const row2 = _items.slice(5, 10);
  const row3 = _items.slice(10);

  return (
    <Box>
      <Text variant="h2" weight="medium" mb={1}>{display_title}</Text>
      <Scroller cancelPadding={globalPadding} arrows>
        <VitrineRow items={row1} mb={0} />
        <VitrineRow items={row2} mb={0}/>
        <VitrineRow items={row3} />
      </Scroller>

    </Box>
  );
};

const VitrineRow = ({ items, ...spacingProps }: { items: { content: Content, image?: string; }[] } & SpacingProps) => (
  <Row cols={{ xs: 2, sm: 3, md: 4, lg: 5 }} {...spacingProps}>
    {items.map(({ content, image }) => (
      <VitrineCard
        key={content.id}
        imagePath={image}
        content={content}
      />
    ))}
  </Row>
);

export default Vitrine;
